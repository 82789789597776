<template>
    <div class="action-share-request">
        <div class="title" v-html="$translate('ACTION_SHARE_REQUEST_TITLE')" />
        <div class="body">
            <div v-html="body" />
            <div class="footer m-t-12" v-html="$translate('ACTION_SHARE_REQUEST_FOOTER')" />
        </div>
        <div class="p-l-12 p-r-12 p-b-12">
            <button
                @click="onClickAcceptShareRequest"
                class="btn btn-primary btn-block"
                :class="{ kakaoMode: $kakaoMode() }"
                v-html="$translate('ACTION_SHARE_REQUEST_ACCEPT')"
            />
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ActionShareRequest',
    props: ['message'],
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        chat() {
            return this.$store.getters.chat
        },
        body() {
            return this.$translate('ACTION_SHARE_REQUEST_BODY')
        },
    },
    methods: {
        async onClickAcceptShareRequest() {
            try {
                const currentComponent = this
                const data = await chatService.acceptContact(this.chat.id, this.message.id)
                this.$modal
                    .custom({
                        component: 'ModalAlertNoX',
                        options: {
                            title: 'INFO',
                            body: data.msg,
                        },
                    })
                    .then(() => {
                        if (this.me.dating_preferences.length > 0) return

                        setTimeout(() => {
                            currentComponent.$modal.custom({
                                component: 'ModalEditDatingPreference',
                            })
                        }, 400)
                    })

                this.sendAirbridgeEvent()
                this.sendFirebaseEvent()

                const msg = this.chat.$$messages.find(msg => msg.id === this.message.id)
                if (!msg) return

                msg.mtype = 'share-done'
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_share_phonenumber',
                },
            })
        },
        sendFirebaseEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'user_share_phonenumber',
                },
            })
        },
    },
}
</script>
<style scoped lang="scss">
.kakaoMode {
    background: $yellow-kakao;
    color: black;
}
</style>
